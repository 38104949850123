<template>
  <div class="yellow_bg">
    <div class="container text-right pt-3 h4">
      <a href="/en/california-policy">Californian Residents Click Here</a>
    </div>
    <div v-if="$lang === 'hr'" class="container pt-5 pb-5">
      <div>
        <h3>Naša posvećenost privatnosti</h3>
        <br />
        <p>
          U Terralogix Grupi zalažemo se ne samo za Vašu privatnost podataka,
          prava i sigurnost, već i za transparentnost tvrtke. Kao čuvari nekih
          od Vaših osobnih podataka, to shvaćamo vrlo ozbiljno. Imajući to u
          vidu, želimo da znate kako prikupljamo i koristimo Vaše osobne
          podatke.
        </p>

        <p class="fw-600">
          Transparentnost. Trudimo se biti transparentni u pogledu Osobnih
          podataka koje prikupljamo i obrađujemo.
        </p>

        <p class="fw-600">
          Jednostavnost. Trudimo se biti što jasniji i sažetiji bez tehničkog i
          pravnog žargona kako bismo opisali naše prakse privatnosti koje će Vam
          pomoći da donesete informirani izbor.
        </p>

        <p class="fw-600">
          Kontrola. Dajemo Vam kontrolu nad Osobnim podacima koje nam dajete te
          kako se oni koriste, dijele i zadržavaju.
        </p>
      </div>

      <div class="mt-4">
        <h3 class="fw-600">Naša Politika privatnosti</h3>
        <p>
          Terralogix grupu čine različite pravne osobe i druga povezana
          poduzeća, uključujući:
        </p>
        <ul>
          <li>
            Terra Adriatica Solutions d.o.o. (nastupa kao Terra Adriatica),
            tvrtka registrirana u Hrvatskoj, OIB: 83760633400 s registriranom
            adresom: Trg Nikole Šubića Zrinskog 14, 10 000 Zagreb, Republika
            Hrvatska.
          </li>
          <li>
            Terralogix Group Ltd – tvrtka registrirana u Engleskoj i Walesu,
            tvrtka # 11736079 s registriranom adresom: Citygate, St. James
            Boulevard, Newcastle Upon Tyne, NE1 4JE, United Kingdom.
          </li>
        </ul>
        <p>
          Ova Politika privatnosti izdana je u ime Terralogix Grupe, stoga kada
          spominjemo „Terralogix”, „mi”, „nas” ili „naš” u ovom dokumentu
          upućujemo na odgovarajuću tvrtku iz Terralogix Grupe odgovornu za
          obradu Vaših Osobnih podataka.
        </p>
        <p>
          Terralogix nudi pristup nizu proizvoda i usluga, uključujući, ali ne
          ograničavajući se na, različite baze podataka društva Terralogix.
          Usluge, proizvodi, podaci i informacije koje Terralogix pruža na ovom
          web-mjestu i/ili preko drugih web-mjesta i kanala na kojima djeluje,
          zajedno sa svim aplikacijama i ostalim povezanim softverskim
          aplikacijama i funkcionalnostima, kolektivno se u ovoj Politici
          privatnosti nazivaju „Usluge“. Korištenje Usluga regulirano je našim
          Uvjetima i odredbama korištenja i bilo kojim drugim ugovorima o usluzi
          (<b>„Korisnički ugovor“</b>) između Terralogix i njihovih korisnika
          (<b>„Kupci“</b>).
        </p>
        <p>
          Ova Politika privatnosti opisuje kako Terralogix postupa s podacima
          koje Vi, drugi korisnici, ili naši Kupci možete pružiti ili koje mi na
          drugi način prikupljamo, a koji Vas mogu izravno ili neizravno
          identificirati (Vaši <b>„Osobni podaci“</b>), kako možemo koristiti
          vaše Osobne podatke, kako štitimo sigurnost vaših Osobnih podataka i
          kako možete promijeniti ili zatražiti izmjenu svojih Osobnih podataka.
        </p>
        <p>
          U mjeri u kojoj su osobni podaci uređeni i zaštićeni zakonom Europske
          unije (<b>„EU“</b>) i/ili UK zakonom o zaštiti podataka (takvi se
          osobni podaci u daljnjem tekstu nazivaju <b>„Osobni podaci EU-a“</b>),
          mi ćemo osigurati da kompletno poštujemo sve relevantne EU i/ili UK
          zakone o zaštiti podataka tijekom obrade osobnih podataka EU-a.
        </p>
        <p>
          Osobni podaci koje namjeravamo obrađivati uključuju Vaše ime, prezime,
          adresu e-pošte, adresu (ulica, grad, županija), telefon, spol,
          nadimak, datum rođenja, OIB (jedinstveni matični broj), mjesto
          rođenja, mjesto posljednjeg prebivališta, Vašu IP-adresu, zaporku i
          Vaše korisničko ime i prema potrebi Vaše aktivnosti i transakcije
          putem Usluga.
        </p>
        <p>
          Terralogix poštuje privatnost korisnika svojih Proizvoda i
          posjetitelja web-mjesta i nastoji zaštiti sigurnost osobnih podataka
          koje obrađuje.
        </p>

        <div>
          <h5>
            Pritiskom na niže navedene poveznice, pogledajte detalje o određenoj
            temi Terralogix Politike privatnosti:
          </h5>
          <ol>
            <li>
              <a href="#1_sec"
                >Kako i kada prikupljamo i koristimo Osobne podatke</a
              >
            </li>
            <li>
              <a href="#2_sec">Naše svrhe obrade</a>
            </li>
            <li>
              <a href="#3_sec">Naša upotreba kolačića</a>
            </li>
            <li>
              <a href="#4_sec"
                >Informacije koje nam pružate Vi i ostali Kupci</a
              >
            </li>
            <li>
              <a href="#5_sec">Kako dijelimo Vaše Osobne podatke</a>
            </li>
            <li>
              <a href="#6_sec">Kako štitimo sigurnost Vaših Osobnih podataka</a>
            </li>
            <li>
              <a href="#7_sec">Dječja privatnost</a>
            </li>
            <li>
              <a href="#8_sec">Vaša prava u vezi s Vašim Osobnim podacima</a>
            </li>
            <li>
              <a href="#9_sec">Koliko dugo čuvamo Vaše Osobne podatke</a>
            </li>
            <li>
              <a href="#10_sec"
                >Kako koristimo Vaše Osobne podatke za obavijesti i marketing</a
              >
            </li>
            <li>
              <a href="#11_sec">Druga web-mjesta</a>
            </li>
            <li>
              <a href="#12_sec">Promjene Politike privatnosti</a>
            </li>
            <li>
              <a href="#13_sec">Kako nas kontaktirati</a>
            </li>
          </ol>
        </div>
      </div>

      <div id="1_sec">
        <h5 class="fw-600">
          1. Kako i kada prikupljamo i koristimo Osobne podatke
        </h5>

        <p>
          Prikupljamo podatke od i o Vama kada se registrirate kod nas ili
          koristite naše Usluge ili pak iz javno dostupnih izvora. Uz to, možemo
          prikupljati i sljedeće osobne podatke:
        </p>

        <p>
          (a) Korisnik je dostavio podatke: Obradit ćemo Vaše Osobne podatke kad
          nam ih dostavi drugi Kupac u vezi s korištenjem naših Usluga. U nekim
          situacijama možemo zabilježiti i Vaše korisničko ime i / ili zaporku
          koju koristite za prijavu u Usluge.
        </p>
        <p>
          Možda ćemo koristiti ove Osobne podatke da odgovorimo na posebne
          zahtjeve za informacije o Uslugama i druge upite. Osobne podatke koje
          ste pružili Vi ili naši Kupci koristimo samo prilikom prijave za
          upotrebu Usluge u svrhe povezane s pružanjem tih Usluga Vama i našim
          Kupcima.
        </p>

        <p>
          (b) Informacije koje dobivamo Vašim korištenjem naših Usluga: Kada
          pristupite našim Uslugama, naši sustavi automatski bilježe podatke
          poput adrese internetskog protokola ("IP"), identifikatora mobilnog
          uređaja, vrste i karakteristike preglednika te koristite li naše
          Usluge i koliko često.
        </p>
        <p>
          Te Osobne podatke obrađujemo kako bismo razumjeli preferencije
          korisnika, kako bi im bolje služili i za poboljšanje naših Usluga.
        </p>

        <p>
          (c) Informacije koje Vi ili drugi Kupci prenosite u naše Usluge:
          Usluge mogu dozvoliti prijenos, objavljivanje, pohranu i pregled
          osobnih podataka ili drugog sadržaja koje ste poslali Vi ili neki
          drugi Korisnik naših usluga prema Uvjetima i odredbama korištenja (<b
            >“Korisnikov podneseni sadržaj”</b
          >). Korisnikov podneseni sadržaj prenesen od Korisnika i, osim ako
          nije drugačije navedeno u ovom stavku i stavku 1(d) „Agregirani
          podaci“, Terralogix će obrađivati ​​kao obrađivač podataka u skladu s
          uputama Kupca. Kupac će biti odgovoran za sav Korisnikov podneseni
          sadržaj, uključujući poštivanje važećih Uvjeta korištenja, Ugovora s
          Kupcem i svih primjenjivih podataka o privatnosti, sigurnosti podataka
          i drugim zakonima i propisima. Osim ako je drugačije predviđeno u ovom
          stavku i stavku 1(d) „Agregirani podaci“ u nastavku.
        </p>

        <p>
          Sadržaj kojeg je poslao Korisnik obrađujemo prvenstveno za pružanje
          Usluga. Također koristimo ove Osobne podatke za rješavanje problema
          sustava, sigurnosti ili podrške ili sumnje da se krše naši Uvjeti i
          odredbe korištenja ili Ugovor s Kupcem i za stvaranje zbirnih podataka
          (vidi 1 (d) „Agregirani podaci“ u nastavku).
        </p>

        <p>
          (d) Agregirani podaci: Mi možemo prikupljati podatke o korištenju
          naših Usluga ili o kategoriji korisnika naših Usluga i kombinirati te
          podatke s drugim podacima koji se odnose na upotrebu naših Usluga od
          Vas i drugih korisnika. Pored toga, možemo stvarati „Agregirane
          podatke“ koristeći Korisnikov podneseni sadržaj i možemo koristiti
          takve Agregirane podatke u vezi s našim pružanjem Usluga i u druge
          poslovne svrhe Terralogix-a te za stvaranje novih proizvoda i usluga.
          „Agregirani podaci“ znače podatke dobivene združivanjem,
          izračunavanjem, analizom ili kompilacijom Korisnikovog podnesenog
          sadržaja i / ili podatke iz drugih izvora, uključujući ostale kupce
          Terralogix-a; pod uvjetom da (i) takvi rezultirajući podaci ne
          identificiraju Vas ili bilo kojeg drugog pojedinca ili Kupca kao izvor
          takvih podataka, i (ii) identiteti Kupca ili pojedinca ne budu
          otkriveni niti razlučivi iz dobivenih podataka bez ulaganja izvanredne
          količine vremena ili truda. Nijedan takav Agregirani podatak neće
          uključivati ​​Vaše Osobne podatke.
        </p>
      </div>

      <div id="2_sec">
        <h4>2. Svrha obrade Osobnih Podataka</h4>

        <p>
          Osobni podaci koje ćemo možda trebati obraditi uključuju Vaše ime,
          prezime, adresu e-pošte, adresu (ulica, grad, županija), telefon,
          spol, nadimak, datum rođenja, OIB (jedinstveni matični broj), mjesto
          rođenja, mjesto rođenja, posljednje prebivalište, Vašu IP-adresu,
          zaporku i Vaše korisničko ime i, prema potrebi, Vaše aktivnosti i
          transakcije na našim Uslugama. Takvi Osobni podaci trebaju nam
          prvenstveno kako bi nam omogućili sastavljanje ugovora s našim Kupcima
          i omogućili Vama korištenje Usluge, da bismo omogućili ispunjavanje
          zakonskih obveza, u svrhu rješavanja problema sustava, sigurnosti ili
          podrške ili sumnje u kršenje
          <a href="/hr/tos">Uvjeta i odredbi korištenja</a>, Korisničkog ugovora
          ili zakona i u mnogim slučajevima možemo koristiti Vaše Osobne podatke
          za ostvarivanje legitimnih interesa, naših i interesa trećih strana,
          pod uvjetom da Vaši interesi i osnovna prava ne prevladavaju nad tim
          interesima. Situacije u kojima ćemo obraditi vaše Osobne podatke radi
          naših legitimnih interesa uključuju neke ili sve od sljedećeg:
        </p>

        <div>
          <div class="p-cont d-flex">
            <div class="side-num">a.</div>
            <div class="side-text">
              <span>
                Da bismo pružili Usluge, poboljšali, izmijenili, personalizirali
                ili na neki drugi način poboljšali i podržali Usluge te vodili
                komunikaciju u korist naših Klijenata i korisnika Usluga.
              </span>
            </div>
          </div>

          <div class="p-cont d-flex">
            <div class="side-num">b.</div>
            <div class="side-text">
              <span>
                Za ažuriranje i održavanje točnosti Osobnih podataka.
              </span>
            </div>
          </div>

          <div class="p-cont d-flex">
            <div class="side-num">c.</div>
            <div class="side-text">
              <span>
                Da bismo poboljšali sigurnost mreže i informacija, uključujući
                sprečavanje neovlaštenog pristupa našim računalnim i
                elektroničkim komunikacijskim sustavima i sprečavanje
                distribucije zlonamjernog softvera.
              </span>
            </div>
          </div>

          <div class="p-cont d-flex">
            <div class="side-num">d.</div>
            <div class="side-text">
              <span>
                Da bismo proveli studije analitike podataka za pregled i bolje
                razumijevanje interakcije korisnika sa Uslugama.
              </span>
            </div>
          </div>
        </div>

        <p>
          Kad god obrađujemo Osobne podatke u ove svrhe, jamčimo Vam da Vaša
          prava na Osobne podatke poštujemo i vodimo računa o tim pravima. Imate
          pravo prigovoriti na ovu obradu ako želite, a ako to želite, molimo
          vas pogledajte
          <a href="#8_sec">8. "Vaša prava u vezi s Vašim Osobnim podacima"</a> u
          nastavku. Imajte na umu da ako uložite prigovor i ne možemo pokazati
          opravdane zakonite razloge za nastavak obrade Vaših Osobnih podataka,
          prestat ćemo obrađivati Vaše Osobne podatke što može utjecati na našu
          sposobnost pružanja određenih usluga i izvršavanje zadataka u Vašu
          korist. To može utjecati i na Vašu mogućnost korištenja Proizvoda.
          Molimo pogledajte i svoja prava koja se tiču Vaših Osobnih podataka u
          <a href="#8_sec">8. "Vaša prava u vezi s Vašim Osobnim podacima"</a>.
        </p>
      </div>

      <div id="3_sec">
        <h4>3. Naša upotreba kolačića</h4>
        <p>
          Koristimo kolačiće, male datoteke s podacima koje ovo web-mjesto može
          prenijeti na Vaš preglednik. Više podataka o kolačićima možete pronaći
          na web-stranicama kao što su www.allaboutcookies.org i
          www.youronlineshoices.eu. Za više informacija o tome što Terralogix
          kolačići koriste i zašto, pogledajte našu
          <a href="/hr/cookie-policy">Politiku o kolačićima</a>.
        </p>
      </div>

      <div id="4_sec">
        <h4>4. Informacije koje nam pružate Vi i ostali Kupci</h4>
        <p>
          Informacije koje dajete o sebi ili drugim živim pojedincima obrađujemo
          kada dobrovoljno sudjelujete u Uslugama ili sudjelujete u javnim
          raspravama. Također možemo dobiti Vaše Osobne podatke od Kupca koji
          vas upišu kao dio svog obiteljskog stabla u vezi s našim uslugama.
          Terralogix ne posjeduje ili ne kontrolira takve Osobne podatke, osim u
          gornjim stavcima 1 (c) i 1 (d), a takve Osobne podatke obrađujemo samo
          u svrhu pružanja naših Usluga uz pristanak naših Kupaca. Naši su Kupci
          odgovorni za osiguravanje poštivanja prava na privatnost, uključujući
          i brigu o prikladnom otkrivanju informacija o prikupljanju i
          korištenju podataka trećih strana. U mjeri u kojoj djelujemo tijekom
          pružanja usluga, obradit ćemo Osobne podatke u skladu s Uvjetima i
          odredbama korištenja naših Usluga, uvjetima našeg Korisničkog ugovora
          i Kupčevom zakonskom uputom. Ako imate bilo kakvih pitanja ili
          nedoumica u vezi s obradom bilo kojeg takvog Osobnog podatka, obratite
          nam se e-poštom na adresu privacy@terraadriatica.hr.
        </p>
      </div>

      <div id="5_sec">
        <h4>5. Kako dijelimo Vaše Osobne podatke</h4>
        <p>
          (a) Među tvrtkama Terralogix Grupe: Osobne podatke možemo dijeliti
          među tvrtkama Terralogix Grupe, uključujući one gore opisane za
          pružanje Proizvoda i za podršku ovog web-mjesta.
        </p>
        <p>
          (b) S pružateljima Usluga treće strane: Možemo pružiti vaše Osobne
          podatke našim vanjskim pružateljima usluga kako bi nam oni mogli
          pomoći u vezi s upravljanjem naših Usluga; na primjer, naši podatkovni
          centri, pružatelji usluga podrške, programeri i druge slične ili
          srodne usluge. Obično sklapamo pismene ugovore s našim pružateljima
          usluga treće strane, zahtijevajući od njih da koriste Osobne podatke
          samo u tu svrhu i zahtijevamo jamstva da će na odgovarajući način
          zaštititi Osobne podatke koji su im povjereni.
        </p>
        <p>
          (c) Za provedbu zakona i pravne svrhe: Možemo otkriti vaše Osobne
          podatke ako smatramo potrebnim ili prikladnim: (i) prema primjenjivom
          zakonu, (ii) u skladu s pravnim postupkom; (iii) kako bi odgovorili na
          zahtjeve državnih i vladinih tijela, uključujući javna i vladina
          tijela izvan vaše zemlje prebivališta; (iv) provodili naše Uvjete i
          odredbe za korištenje i Korisničke ugovore; (v) zaštitili naše
          poslovanje ili poslovanje bilo koje od naših pridruženih kompanija;
          (vi) zaštitili naša prava, privatnost, sigurnost ili imovinu i / ili
          prava naših pridruženih kompanija, vas ili drugih; i (vii) omogućili
          traženje dostupnih pravnih sredstava ili ograničavanje štete koju
          bismo mogli pretrpjeti.
        </p>
        <p>
          (d) Prijenosi izvan Europskog gospodarskog prostora („EEA“):
          Dijeljenje vaših Osobnih podataka EU-a kako je gore opisano može
          uključivati ​​prijenos Vaših Osobnih podataka EU-a izvan EEA-e,
          uključujući u i u Sjedinjene Države. Kad god prenosimo bilo koje Vaše
          Osobne podatke EU izvan EEA, imat ćemo ili ćemo uspostaviti
          odgovarajuću razinu zaštite kako bismo osigurali da su takvi prijenosi
          u skladu s važećim zakonima o zaštiti podataka EU i / ili Ujedinjenog
          Kraljevstva, uključujući i standardne klauzule o zaštiti podataka koje
          je usvojila Komisija EU prilikom prijenosa podataka između
          Terralogix-ovih podružnica. Ako su Vam potrebne dodatne informacije o
          ovoj zaštitnoj mjeri, možete ih zatražiti od Tima za zaštitu podataka
          putem našeg kontakt-obrasca s web- stranice slanjem e-pošte na adresu
          privacy@terraadriatica.hr.
        </p>
      </div>

      <div id="6_sec">
        <h4>6. Kako štitimo sigurnost Vaših Osobnih podataka</h4>

        <p>
          Zalažemo se za održavanje sigurnosti Osobnih podataka. Koristimo
          razumne organizacijske, tehničke i administrativne mjere kako bi
          zaštitili Osobne podatke prikupljene na način opisan u ovim Pravilima
          o privatnosti od gubitka, krađe, zloupotrebe i neovlaštenog pristupa,
          otkrivanja, izmjena i uništenja. Osobama koje rade za nas omogućavamo
          pristup i korištenje Osobnih podataka koje dajete samo da bi služile
          zakonitim potrebama poslovanja i usluga u skladu s ovim Pravilima o
          privatnosti. Od naših davatelja usluga također tražimo da provode
          odgovarajuće sigurnosne mjere. Imajte na umu da nijedna metoda
          elektroničkog prijenosa ili pohrane nije 100% sigurna. Ako imate
          razloga vjerovati da Vaša interakcija s nama više nije sigurna, molimo
          da nas odmah obavijestite o problemu (pogledajte
          <a href="#11_sec">11. „Kako nas kontaktirati“</a> u nastavku). Osobne
          podatke zadržavamo sve dok su nam razumno potrebni u pravne, poslovne
          ili ugovorne svrhe. Pri određivanju razdoblja čuvanja podataka uzimamo
          u obzir lokalne zakone, ugovorne obveze, tehnološka ograničenja te
          očekivanja i zahtjeve naših Kupaca. Kad nam više nisu potrebni Osobni
          podaci, sigurno ih brišemo ili uništavamo.
        </p>
      </div>

      <div id="7_sec">
        <h4>7. Dječja privatnost</h4>

        <p>
          Brinemo za zaštitu privatnosti djece mlađe od 16 godina. Svjesno ne
          tražimo niti prikupljamo podatke od osoba mlađih od 16 godina. Ako
          postanemo svjesni da nam je dijete mlađe od 16 godina dostavilo Osobne
          podatke, zatvorit ćemo račun i ograničiti njihove podatke. Ako
          smatrate da smo možda prikupljali Osobne podatke od djeteta mlađeg od
          16 godina, kontaktirajte nas putem obrasca za kontakt s naše
          web-stranice e-poštom na adresu privacy@terraadriatica.hr.
        </p>
      </div>

      <div id="8_sec">
        <h4>8. Vaša prava u vezi s Vašim Osobnim podacima</h4>

        <p>
          Možete imati pravo na pristup informacijama koje obrađujemo o Vama i
          tražiti da ih ispravimo, izmijenimo ili obrišemo ako su netočne ili ih
          obradimo kršenjem važećih zakona, ako je takvo pravo zakonom
          predviđeno u odgovarajućoj nadležnosti. Ova se prava možda ne
          primjenjuju u nekim slučajevima i, ako podnesete zahtjev, obavijestit
          ćemo Vas da je to slučaj. Ako želite zatražiti pristup svojim Osobnim
          podacima EU, ispravak ili izmjenu ili zatražiti brisanje Vaših osobnih
          podataka EU, kontaktirajte nas e-poštom na adresu
          privacy@terraadriatica.hr.
        </p>
      </div>

      <div id="9_sec">
        <h4>9. Koliko dugo čuvamo Vaše Osobne podatke</h4>

        <p>
          Terralogix provodi opravdane postupke kako bi se osiguralo da su Vaši
          Osobni podaci pouzdani za namjeravanu upotrebu, točni, potpuni i
          aktualni.
        </p>
        <ul>
          <li>
            Vaše Osobne podatke zadržat ćemo samo onoliko dugo koliko je
            potrebno da ispunimo svrhu u koju smo ih prikupili, uključujući
            svrhu ispunjavanja bilo kakvih pravnih, računovodstvenih ili
            izvještajnih zahtjeva, kao i drugih čimbenika koje zahtjeva
            <a
              href="https://eur-lex.europa.eu/legal-content/EN/TXT/PDF/?uri=CELEX:32016R0679&from=EN"
              >Opća uredba o zaštiti podataka</a
            >
            ili drugi zakoni kojima smo podvrgnuti.
          </li>
          <li>
            Kad više nije potrebno čuvati Vaše Osobne podatke, sigurno ćemo ih
            izbrisati u skladu s primjenjivim zakonom i propisima.
          </li>
          <li>
            U nekim slučajevima možemo Vaše podatke učiniti anonimnima (tako da
            se više ne mogu povezati s Vama) u istraživačke ili statističke
            svrhe. U tom slučaju možemo te podatke koristiti u nedogled bez da
            Vas o tome obavijestimo.
          </li>
          <li>
            Ako zatražite da vam prestanemo slati marketinške materijale,
            nastavit ćemo voditi evidenciju Vaših kontakt-podataka i
            odgovarajućih podataka kako bismo udovoljili Vašem zahtjevu da Vas
            ne kontaktiramo.
          </li>
        </ul>
      </div>

      <div id="10_sec">
        <h4>
          10. Kako koristimo Vaše Osobne podatke za obavijesti i marketing
        </h4>
        <p>
          U skladu s važećim zakonima, možemo Vam poslati personalizirane
          marketinške informacije, uključujući e-poštu, a takve informacije mogu
          uključivati ​​ažuriranje proizvoda i usluga, vijesti u industriji,
          naše događaje, aktivnosti i ponude, informacije o našem poslu, osoblju
          i savjete. Možemo kombinirati Vaše Osobne podatke, poput dobi,
          povijesti transakcija, korištenja računa radi poboljšanja vrijednosti
          i specifičnosti ovih komunikacija.
        </p>
        <p>
          Možete nas tražiti da Vam u bilo kojem trenutku prestanemo slati
          marketinške poruke ili informacije slijedeći vezu "odjava" na bilo
          kojoj marketinškoj poruci koja Vam je poslana ili ažuriranjem Vaših
          postavki profila izravno na platformi Terralogix. Imajte na umu da
          može proći do 10 dana dok ovo stupi na snagu. Ako se odlučite za
          primanje ovih marketinških poruka ili publikacija, to se neće odnositi
          na Osobne podatke prikupljene ili dostavljene nama a u vezi s
          određenom svrhom, zahtjevom, nalogom, događajem ili aktivnošću ili
          bilo kakvim poslovima s Vama i još uvijek možete primati e-poruke od
          nas u vezi s radom Usluge Terralogix-a, kao što su potvrde
          transakcija.
        </p>
      </div>

      <div id="11_sec">
        <h4>11. Druga web-mjesta</h4>
        <p>
          Ova se Pravila o Privatnosti odnose na Osobne podatke prikupljene u
          vezi s Vašim korištenjem naših Usluga. Informacije prikupljene na
          web-mjestu treće strane povezane s našim Uslugama podliježu uvjetima
          Politike privatnosti koji se primjenjuju na takvo web- mjesto treće
          strane, a ne uvjetima ove Politike privatnosti. Terralogix nije
          povezan s web-mjestima trećih strana i ne preispituje njihova pravila
          o privatnosti. Ne možemo jamčiti za privatnost bilo kakvih podataka
          koje mogu dobiti treće strane na drugim web-mjestima. Preporučujemo
          Vam da pregledate pravila o privatnosti bilo kojeg web-mjesta koje
          posjetite prije nego što dostavite Osobne podatke putem tog
          web-mjesta.
        </p>
      </div>

      <div id="12_sec">
        <h4>12. Promjene Politike privatnosti</h4>
        <p>
          Povremeno možemo revidirati ovu Politiku Privatnosti da bismo je
          prilagodili novoj ili revidiranoj funkcionalnosti web-stranice, novim
          ili revidiranim aspektima Proizvoda, Internet tehnologija ili
          primjenjivog zakona. Rukovanje Osobnim podacima regulirano je verzijom
          ove Politike o privatnosti koja je na snazi ​​kada su takve
          informacije primljene. Stoga biste je povremeno trebali pregledati
          kako biste bili sigurni da ste upoznati s ažurnim primjenjivim
          odredbama. Vaša daljnja upotreba naših usluga nakon što objavimo
          izmjene ove Politike privatnosti ukazuje na Vaš pristanak na
          izmijenjene uvjete ove Politike privatnosti.
        </p>
      </div>

      <div id="13_sec">
        <h4>13. Kako nas kontaktirati</h4>
        <p>
          Da biste nas kontaktirali u vezi s ovom Politikom privatnosti putem
          pošte, molimo vas koristite jednu od sljedećih adresa:
        </p>
        <ul>
          <li>
            Terra Adriatica Solutions d.o.o., Trg Nikole Šubića Zrinskog 14, 10
            000 Zagreb, Republika Hrvatska.
          </li>
          <li>
            Terralogix Group Ltd, Citygate, St. James Boulevard, Newcastle Upon
            Tyne, NE1 4JE, United Kingdom
          </li>
        </ul>
        <p>
          Da biste nas kontaktirali u vezi s ovim Pravilima o Privatnosti putem
          e-pošte, molimo učinite to putem privacy@terraadriatica.hr.
        </p>
        <p>
          Istražit ćemo i pokušati riješiti sve žalbe ili sporove koje primimo u
          vezi s obradom Vaših Osobnih podataka. U mjeri u kojoj su Vaši Osobni
          podaci EU Osobni podaci, a niste zadovoljni rješenjem bilo koje žalbe,
          možete se obratiti Uredu povjerenika Informacija Velike Britanije radi
          dodatnih informacija i pomoći.
        </p>
        <p>
          Registrirani smo kao kontrolor podataka u Uredu povjerenika
          informacija u Velikoj Britaniji. Naš registarski broj za zaštitu
          podataka je ZA519594.
        </p>
      </div>
    </div>

    <div v-else-if="$lang === 'es'" class="container pt-5 pb-5">
      <div>
        <h3>Nuestro compromiso con la privacidad</h3>
        <br />
        <p>
          En Terralogix, estamos comprometidos no solo con la privacidad de sus
          datos, con sus derechos y con su seguridad, sino también con la
          transparencia de la compañía. Como responsables de parte de su
          información personal, nos tomamos esto muy en serio. Teniendo esto
          presente, queremos que sepa cómo recopilamos y utilizamos sus datos
          personales.
        </p>

        <p class="fw-600">
          Transparencia. Trabajamos arduamente para ser transparentes sobre qué
          Información Personal recopilamos y procesamos.
        </p>

        <p class="fw-600">
          Sencillez. Intentamos ser lo más claros y concisos posible sin argot
          técnico y legal para describir nuestras prácticas de privacidad, para
          ayudarle a tomar decisiones informadas.
        </p>

        <p class="fw-600">
          Control. Le damos control sobre la Información Personal que nos
          proporciona y cómo se utiliza, se comparte y se guarda.
        </p>
      </div>

      <div class="mt-4">
        <h3 class="fw-600">Nuestra Política de Privacidad</h3>
        <p>
          El Grupo Terralogix consta de varias personas jurídicas y empresas
          afiliadas, que incluyen:
        </p>
        <ul>
          <li>
            Terra Adriatica Solutions d.o.o. (opera bajo el nombre comercial de
            Terra Adriatica), una compañía registrada en Croacia, NIF (OIB):
            83760633400 con domicilio social en Trg Nikole Šubića Zrinskog 14,
            10000 Zagreb, Croacia.
          </li>
          <li>
            Terralogix Group Ltd – una compañía registrada en Inglaterra y
            Gales, compañía n.º 11736079 con domicilio social en Citygate, St.
            James Boulevard, Newcastle Upon Tyne, NE1 4JE, Reino Unido.
          </li>
        </ul>
        <p>
          Esta Política de Privacidad se emite en nombre del Grupo Terralogix,
          por lo que cuando mencionamos “Terralogix”, “nosotros”, “nos” o
          “nuestro” en esta política de privacidad, nos referimos a la compañía
          relevante del Grupo Terralogix responsable del tratamiento de sus
          Datos Personales.
        </p>
        <p>
          Terralogix ofrece acceso a una amplia gama de productos y servicios,
          incluidas, sin limitación, las diversas bases de datos de Terralogix.
          Los servicios, productos, datos e información proporcionados por
          Terralogix en este Sitio Web y/o a través de otros sitios web y
          canales que opera, junto con cualquier Aplicación y cualquier otra
          aplicación de software y funcionalidad asociadas, se denominan
          colectivamente en esta Política de Privacidad “los Servicios”. El uso
          de los Servicios se rige por nuestros Términos y Condiciones de uso y
          cualquier otro acuerdo de servicios (<b>“Contrato del Cliente”</b>)
          entre Terralogix y sus clientes (<b>“Clientes”</b>).
        </p>
        <p>
          Esta Política de Privacidad describe cómo Terralogix trata la
          información que usted, otros usuarios o nuestros Clientes pueden
          proporcionarnos, o que de otra manera recopilamos, que puede
          identificarle directa o indirectamente (su
          <b>“Información Personal”</b>), cómo podemos utilizar su Información
          Personal, cómo protegemos la seguridad de su Información Personal y
          cómo puede cambiar o solicitar que modifiquemos su Información
          Personal.
        </p>
        <p>
          En la medida en que la Información Personal esté regida y protegida
          por las leyes de protección de datos de la Unión Europea (<b>“UE”</b>)
          y/o del Reino Unido (dicha Información Personal en lo sucesivo se
          denomina
          <b>“Información Personal de la UE”</b>), nos aseguraremos de cumplir
          con todas las leyes de protección de datos pertinentes de la UE y/o el
          Reino Unido al procesar la Información Personal de la UE.
        </p>
        <p>
          La Información Personal que pretendemos procesar incluye su nombre,
          apellido, dirección de correo electrónico, dirección (calle, ciudad,
          condado), teléfono, sexo, apodo, fecha de nacimiento, NIF (número de
          identificación fiscal), lugar de nacimiento, último lugar de
          residencia, su dirección IP, su contraseña y su nombre de usuario, y,
          cuando corresponda, su actividad y transacciones a través de los
          Servicios.
        </p>
        <p>
          Terralogix respeta la privacidad de los usuarios de sus Productos y
          visitantes del Sitio Web y se esfuerza por proteger la seguridad de la
          Información Personal que procesa.
        </p>

        <div>
          <h5>
            Haga clic en los siguientes enlaces para ver la política de
            Terralogix sobre cada tema:
          </h5>
          <ol>
            <li>
              <a href="#1_sec"
                >Cómo y cuándo recopilamos y utilizamos la Información
                Personal</a
              >
            </li>
            <li>
              <a href="#2_sec">Nuestros propósitos del procesamiento</a>
            </li>
            <li>
              <a href="#3_sec">Nuestro uso de cookies</a>
            </li>
            <li>
              <a href="#4_sec"
                >Información que usted y otros clientes nos proporcionan</a
              >
            </li>
            <li>
              <a href="#5_sec">Cómo compartimos su Información Personal</a>
            </li>
            <li>
              <a href="#6_sec"
                >Cómo protegemos la seguridad de su Información Personal</a
              >
            </li>
            <li>
              <a href="#7_sec">Privacidad de los niños</a>
            </li>
            <li>
              <a href="#8_sec"
                >Sus derechos con respecto a su Información Personal</a
              >
            </li>
            <li>
              <a href="#9_sec"
                >Cuánto tiempo conservamos su Información Personal</a
              >
            </li>
            <li>
              <a href="#10_sec"
                >Cómo utilizamos sus Datos Personales para fines de
                notificaciones y marketing</a
              >
            </li>
            <li>
              <a href="#11_sec">Otros sitios web</a>
            </li>
            <li>
              <a href="#12_sec">Modificaciones a esta Política de Privacidad</a>
            </li>
            <li>
              <a href="#13_sec">Cómo contactarnos</a>
            </li>
          </ol>
        </div>
      </div>

      <div id="1_sec">
        <h5 class="fw-600">
          1. Cómo y cuándo recopilamos y utilizamos la Información Personal
        </h5>

        <p>
          Recopilamos información de y sobre usted cuando se registra con
          nosotros o utiliza nuestros Servicios, o de fuentes disponibles
          públicamente. Además, también podemos recopilar la siguiente
          Información Personal:
        </p>

        <p>
          (a) Información enviada por el cliente: Procesaremos su Información
          Personal cuando otro Cliente nos la proporcione en relación con el uso
          de nuestros Servicios. En algunos casos, también podemos registrar su
          nombre de usuario y/o contraseña que usted proporciona para iniciar
          sesión en los Servicios.
        </p>
        <p>
          Podemos utilizar esta Información Personal para responder a
          solicitudes específicas de información sobre los Servicios y otras
          consultas. Solo utilizamos la Información Personal que usted o
          nuestros Clientes nos proporcionan al registrarse para utilizar un
          Servicio para fines relacionados con la prestación de dichos Servicios
          a usted y a nuestros Clientes.
        </p>

        <p>
          (b) Información que obtenemos de su uso de nuestros Servicios: Cuando
          accede a nuestros Servicios, nuestros sistemas registran
          automáticamente información como su dirección de Protocolo de Internet
          (“IP”), identificador de dispositivo móvil, tipo y características del
          navegador, y con qué frecuencia usa nuestros Servicios.
        </p>
        <p>
          Procesamos esta Información Personal para comprender las preferencias
          de los usuarios, para servir mejor a los usuarios y para mejorar
          nuestros Servicios.
        </p>

        <p>
          (c) Información que usted u otros Clientes carguen a nuestros
          Servicios: Los Servicios pueden permitir la carga, publicación,
          almacenamiento y visualización de Información Personal u otro
          contenido enviado por usted o por otro Cliente de nuestros Servicios
          en virtud de los Términos y Condiciones de uso (<b
            >“Contenido Enviado por el Usuario”</b
          >). El Cliente proporciona el Contenido Enviado por el Usuario y,
          salvo disposición en contrario en este párrafo y en la letra (d) del
          apartado 1 <b>“Datos Agregados”</b> que figura a continuación,
          Terralogix lo procesará como procesador de datos de acuerdo con las
          instrucciones del Cliente. El Cliente será responsable de todo el
          Contenido Enviado por el Usuario, incluido de cumplir con las
          aplicables Condiciones de Uso, el Contrato del Cliente y todas las
          leyes y regulaciones aplicables en cuanto a la privacidad, seguridad
          de datos y otras leyes y regulaciones. Salvo disposición en contrario
          en este apartado o en la letra (d) del apartado 1
          <b>“Datos Agregados”</b>
          que figura a continuación.
        </p>

        <p>
          Procesamos el Contenido Enviado por el Usuario principalmente para
          prestar los Servicios. También utilizamos esta Información Personal
          para abordar problemas de sistema, seguridad o apoyo, o una presunta
          violación de nuestros Términos y Condiciones de uso o del Contrato del
          Cliente, y para crear información agregada (consulte la letra (d) del
          apartado 1 <b>“Datos Agregados”</b> a continuación). below)
        </p>

        <p>
          (d) Datos Agregados: Podemos recopilar información sobre el uso de
          nuestros Servicios, o sobre una categoría de usuarios de nuestros
          Servicios, y combinar dicha información con otra información
          relacionada con el uso de nuestros Servicios por usted y otros
          usuarios. Además, podemos crear “Datos Agregados” utilizando el
          Contenido Enviado por el Usuario y utilizar dichos Datos Agregados en
          relación con nuestra prestación de los Servicios y para otros fines
          comerciales de Terralogix, y para crear nuevos productos y servicios.
          “Datos Agregados” se refieren a los datos resultantes de la
          agregación, cálculo, análisis o compilación de Contenido Enviado por
          el Usuario y/o datos de otras fuentes, incluidos otros clientes de
          Terralogix; siempre que (i) dichos datos resultantes no le
          identifiquen a usted ni a ninguna otra persona o Cliente como la
          fuente de dichos datos, y (ii) las identidades del Cliente o la
          persona no se revelen ni se puedan discernir a partir de los datos
          resultantes sin una cantidad extraordinaria de tiempo o esfuerzo.
          Ninguno de estos Datos Agregados en incluirá su Información Personal.
        </p>
      </div>

      <div id="2_sec">
        <h4>2. Fundamentos para el procesamiento de Información Personal</h4>

        <p>
          La Información personal que podemos necesitar procesar incluye su
          nombre, apellido, dirección de correo electrónico, dirección (calle,
          ciudad, condado), teléfono, sexo, apodo, fecha de nacimiento, NIF
          (número de identificación fiscal), lugar de nacimiento, último lugar
          de residencia, su dirección IP, su contraseña y su nombre de usuario,
          y, cuando corresponda, su actividad y transacciones en nuestros
          Servicios. Necesitamos dicha Información Personal principalmente para
          poder realizar los contratos con nuestros Clientes y permitirle usar
          los Servicios, para que podamos cumplir con las obligaciones legales,
          con el fin de abordar problemas de sistema, seguridad o apoyo, o una
          presunta violación de los Términos y Condiciones de uso, del Contrato
          del Cliente o la ley y, en muchos casos, podemos utilizar su
          Información Personal para perseguir nuestros intereses legítimos e
          intereses de terceros, siempre que sus intereses y derechos
          fundamentales no anulen esos intereses. Los casos en los que
          procesaremos su Información Personal para nuestros intereses legítimos
          incluyen algunos o todos los siguientes:
        </p>

        <div>
          <div class="p-cont d-flex">
            <div class="side-num">a.</div>
            <div class="side-text">
              <span>
                Para prestar los Servicios, mejorar, modificar, personalizar o
                de otra manera mejorar y apoyar los Servicios y realizar
                comunicaciones en beneficio de nuestros Clientes y usuarios de
                los Servicios.
              </span>
            </div>
          </div>

          <div class="p-cont d-flex">
            <div class="side-num">b.</div>
            <div class="side-text">
              <span>
                Para actualizar y mantener la exactitud de la Información
                Personal.
              </span>
            </div>
          </div>

          <div class="p-cont d-flex">
            <div class="side-num">c.</div>
            <div class="side-text">
              <span>
                Para mejorar la seguridad de la red y la información, incluida
                la prevención del acceso no autorizado a nuestros sistemas de
                comunicaciones electrónicas y de equipos, y la prevención de la
                distribución de software malintencionado.
              </span>
            </div>
          </div>

          <div class="p-cont d-flex">
            <div class="side-num">d.</div>
            <div class="side-text">
              <span>
                Para realizar estudios de análisis de datos para revisar y
                comprender mejor cómo interactúan los usuarios con los
                Servicios.
              </span>
            </div>
          </div>
        </div>

        <p>
          Siempre que procesemos la Información Personal para estos fines, nos
          aseguramos de mantener sus derechos de Información Personal en alta
          estima y de tomar en consideración estos derechos. Si lo desea, tiene
          derecho a oponerse a este procesamiento. Si lo desea, consulte
          <a href="#8_sec"
            >8. “Sus derechos con respecto a su Información Personal” a
            continuación.</a
          >
          a continuación. Tenga en cuenta que, si se opone y nosotros no podemos
          demostrar motivos legítimos para continuar procesando su Información
          Personal, dejaremos de procesar su Información Personal, lo que puede
          afectar nuestra capacidad de prestarle ciertos servicios y llevar a
          cabo tareas en su beneficio. Eso también puede afectar su capacidad
          para usar los Productos. Por favor, consulte también sus derechos con
          respecto a su Información Personal en
          <a href="#8_sec"
            >8. “Sus derechos con respecto a su Información Personal”</a
          >.
        </p>
      </div>

      <div id="3_sec">
        <h4>3. Nuestro uso de cookies</h4>
        <p>
          Utilizamos cookies, que son pequeños archivos de datos que este Sitio
          Web puede transferir a su navegador. Puede encontrar más información
          sobre las cookies en sitios web como www.allaboutcookies.org y
          www.youronlinechoices.eu. Para más información sobre qué cookies usa
          Terralogix y por qué, por favor consulte nuestra
          <a href="/en/cookie-policy">Política de Cookies</a>.
        </p>
      </div>

      <div id="4_sec">
        <h4>4. Información que usted y otros clientes nos proporcionan</h4>
        <p>
          Procesamos la información que usted proporciona sobre sí mismo u otros
          individuos vivos cuando contribuye voluntariamente a los Servicios o
          participa en discusiones de la comunidad. También podemos obtener su
          Información Personal de un Cliente que le añada como parte de su árbol
          genealógico en relación con nuestros Servicios. Terralogix no es
          propietario ni controla dicha Información Personal, a reserva de lo
          dispuesto en los párrafos 1(c) y 1(d) anteriores, y, además,
          gestionamos dicha Información Personal solo en la capacidad de prestar
          nuestros Servicios con el consentimiento de nuestros Clientes.
          Nuestros Clientes son responsables de asegurar que se respeten los
          derechos de privacidad, incluida la garantía de apropiadas
          divulgaciones de recopilación y uso de datos de terceros. En la medida
          en que actuemos en la capacidad de prestar servicios, procesaremos la
          Información Personal de acuerdo con los Términos y Condiciones de uso
          de nuestros Servicios, los términos de nuestro Contrato del Cliente y
          la instrucción legal del Cliente. Si tiene alguna pregunta o
          preocupación sobre el procesamiento de cualquier parte de dicha
          Información Personal, por favor contáctenos por correo electrónico a
          privacy@terraadriatica.hr.
        </p>
      </div>

      <div id="5_sec">
        <h4>5. Cómo compartimos su Información Personal</h4>
        <p>
          (a) Entre las compañías del Grupo Terralogix: Podemos compartir
          Información Personal entre las compañías Terralogix, incluidas las
          descritas anteriormente para suministrar los Productos y en apoyo de
          este Sitio Web.
        </p>
        <p>
          (b) Con proveedores de servicios de terceros: Podemos proporcionar su
          Información Personal a nuestros proveedores de servicios de terceros
          para que nos ayuden en relación con la operación de nuestros
          Servicios; por ejemplo, nuestros centros de datos, proveedores de
          servicios de apoyo, desarrolladores y otros servicios similares o
          relacionados. Generalmente celebramos contratos por escrito con
          nuestros proveedores de servicios de terceros, exigiendo que utilicen
          la Información Personal solo para ese fin y requerimos garantías de
          que protegerán apropiadamente la Información Personal que se les
          confíe.
        </p>
        <p>
          (c) Para la aplicación de la ley y para fines legales: Podemos
          divulgar su Información Personal como consideremos necesario o
          apropiado: (i) en virtud de la ley vigente, (ii) para cumplir con el
          proceso legal; (iii) para responder a las solicitudes de las
          autoridades públicas y gubernamentales, incluidas las autoridades
          públicas y gubernamentales fuera de su país de residencia; (iv) para
          hacer cumplir nuestros Términos y Condiciones de uso y los Contratos
          del Cliente; (v) para proteger nuestras operaciones o las de
          cualquiera de nuestras compañías afiliadas; (vi) para proteger
          nuestros derechos, privacidad, seguridad o propiedad, y/o los de
          nuestras compañías afiliadas, de usted y de los demás; y (vii) para
          permitirnos buscar remedios disponibles o limitar los daños que
          podamos sufrir.
        </p>
        <p>
          (d) Transferencias fuera del Espacio Económico Europeo (“EEE”): El
          intercambio de su Información Personal de la UE, como se ha descrito
          anteriormente, puede implicar la transferencia de su Información
          Personal de la UE fuera del EEE, incluyendo a y en los Estados Unidos.
          Siempre que transfiramos cualquier Información Personal de la UE fuera
          del EEE, tendremos o estableceremos un nivel adecuado de protección
          para asegurar que dichas transferencias cumplan con las leyes de
          protección de datos aplicables de la UE y/o del Reino Unido,
          incluyendo, con respecto a las transferencias entre compañías
          afiliadas de Terralogix, las cláusulas estándar de protección de datos
          adoptadas por la Comisión de la UE. Si necesita más información sobre
          esta medida de protección, puede solicitarla al Equipo de Protección
          de Datos, contactándonos utilizando el formulario de contacto en
          nuestro sitio web, por correo electrónico a privacy@terraadriatica.hr.
        </p>
      </div>

      <div id="6_sec">
        <h4>6. Cómo protegemos la seguridad de su Información Personal</h4>

        <p>
          Estamos comprometidos a mantener la seguridad de la Información
          Personal. Usamos medidas organizativas, técnicas y administrativas
          razonables para asegurar y proteger la Información Personal,
          recopilada tal y como se indica en esta Política de Privacidad, contra
          pérdida, robo, uso indebido y acceso no autorizado, divulgación,
          alteración o destrucción. Permitimos que las personas que trabajan
          para nosotros accedan y utilicen la Información Personal que usted nos
          proporciona solo para atender necesidades legítimas de negocios y
          servicios de conformidad con esta Política de Privacidad. También
          requerimos que nuestros proveedores de servicios de terceros
          implementen medidas de seguridad apropiadas.
        </p>

        <p>
          Recuerde, sin embargo, que ningún método de transmisión o
          almacenamiento electrónico es 100 % seguro. Si tiene motivos para
          creer que su interacción con nosotros ya no es segura, por favor,
          contáctanos y notifíquenos acerca de cualquier problema inmediatamente
          (consulte <a href="#11_sec">11. “Cómo contactarnos””</a>
          a continuación). Conservamos la Información Personal durante el tiempo
          que razonablemente lo requerimos para fines legales, comerciales o
          contractuales. Al determinar los períodos de retención de datos,
          consideramos las leyes locales, las obligaciones contractuales, las
          limitaciones tecnológicas y las expectativas y requisitos de nuestros
          Clientes. Cuando ya no necesitamos la Información Personal, la
          eliminamos o destruimos de forma segura.
        </p>
      </div>

      <div id="7_sec">
        <h4>7. Privacidad de los niños</h4>

        <p>
          Nos preocupamos por proteger la privacidad de los niños de 16 años o
          menos. No solicitamos ni recopilamos información a sabiendas de
          personas menores de 16 años. Si descubrimos que un niño menor de 16
          años nos ha proporcionado Datos Personales, cerraremos la cuenta y
          restringimos su información. Si usted cree que podríamos haber
          recopilado Datos Personales de un niño menor de 16 años, por favor
          contáctenos usando el formulario de contacto en nuestro sitio web por
          correo electrónico a privacy@terraadriatica.hr.
        </p>
      </div>

      <div id="8_sec">
        <h4>8. Sus derechos con respecto a su Información Personal</h4>

        <p>
          Puede tener derecho a acceder a la Información que procesamos sobre
          usted y a solicitar que la corrijamos, modifiquemos o eliminemos si es
          inexacta, o se procesa en violación de las leyes aplicables, si tal
          derecho está previsto por la ley en la jurisdicción aplicable. Estos
          derechos pueden no aplicarse en algunos casos y, si usted envía una
          solicitud, le informaremos si es así. Si desea solicitar acceso a su
          Información Personal de la UE o una corrección o modificación de la
          misma, o solicitar la eliminación de su Información Personal de la UE,
          póngase en contacto con nosotros por correo electrónico a
          privacy@terraadriatica.hr.
        </p>
      </div>

      <div id="9_sec">
        <h4>9. Cuánto tiempo conservamos su Información Personal</h4>

        <p>
          Terralogix mantiene procedimientos razonables para garantizar que sus
          Datos Personales sean fiables para su uso previsto, precisos,
          completos y actuales.
        </p>
        <ul>
          <li>
            Solo conservaremos sus Datos Personales durante el tiempo necesario
            para cumplir los fines para los que los hemos recopilado, incluyendo
            para satisfacer cualquier requisito legal, contable, o de informe,
            así como otros factores requeridos por el Reglamento General de
            Protección de Datos u otras leyes a las que estamos sujetos.
          </li>
          <li>
            Cuando ya no sea necesario conservar sus Datos Personales, los
            eliminaremos de forma segura, sujeto a las leyes y las regulaciones
            aplicables.
          </li>
          <li>
            En algunas circunstancias, podemos anonimizar sus Datos Personales
            (de modo que ya no se puedan asociar con usted) para fines de
            investigación o estadísticos, en cuyo caso podemos utilizar esta
            información indefinidamente sin previo aviso.
          </li>
          <li>
            Si solicita que dejemos de enviarle materiales de marketing,
            seguiremos manteniendo un registro de sus datos de contacto e
            información apropiada para poder cumplir con su solicitud de no ser
            contactado por nosotros.
          </li>
        </ul>
      </div>

      <div id="10_sec">
        <h4>
          10. Cómo utilizamos sus Datos Personales para fines de notificaciones
          y marketing
        </h4>
        <p>
          De conformidad con las leyes aplicables, podemos enviarle información
          de marketing personalizada, incluyendo por correo electrónico, y dicha
          información puede incluir noticias sobre productos y servicios,
          noticias del sector, nuestros eventos, actividades y ofertas,
          información sobre nuestro negocio y personal y consejos. Podemos
          combinar sus Datos Personales, como la edad, el historial de
          transacciones, el uso de la cuenta para mejorar el valor y la
          especificidad de estas comunicaciones.
        </p>
        <p>
          Puede solicitar que dejemos de enviarle mensajes de marketing o de
          información en cualquier momento haciendo clic en el enlace “Cancelar
          suscripción” en cualquier mensaje de marketing que reciba o
          actualizando sus preferencias de contacto directamente en la
          plataforma Terralogix. Tenga en cuenta que puede tardar hasta 10 días
          para que esto surta efecto. Cuando opta por no recibir estos mensajes
          o publicaciones de marketing, esto no se aplicará a los Datos
          Personales recopilados o proporcionados a nosotros en relación con un
          propósito, solicitud, orden, evento o actividad específicos o
          cualquier trato con usted, y aún puede recibir mensajes de correo
          electrónico de nosotros, relacionados con el funcionamiento del
          servicio Terralogix, como confirmaciones de transacciones.
        </p>
      </div>

      <div id="11_sec">
        <h4>11. Otros sitios web</h4>
        <p>
          Esta Política de Privacidad se aplica a la Información Personal
          recopilada en relación con su uso de nuestros Servicios. La
          información enviada a un sitio web de terceros vinculado a nuestros
          Servicios está sujeta a los términos de la política de privacidad
          aplicable a dicho sitio web de terceros, y no a los términos de esta
          Política de Privacidad. Terralogix no está asociado con estos sitios
          web de terceros y no revisa sus políticas de privacidad. No podemos
          garantizar la privacidad de ninguna información que puedan obtener
          terceros en estos otros sitios web. Le animamos a revisar las
          políticas de privacidad de cualquier sitio web que visite antes de
          proporcionar información personal a través de ese sitio web.
        </p>
      </div>

      <div id="12_sec">
        <h4>12. Modificaciones a esta Política de Privacidad</h4>
        <p>
          Podemos revisar esta Política de Privacidad de vez en cuando para
          abordar la funcionalidad nueva o revisada del sitio web, los aspectos
          nuevos o revisados de los Productos, la tecnología de internet o la
          ley aplicable. El tratamiento de la Información Personal se rige por
          la versión de esta Política de Privacidad vigente cuando se recibe
          dicha información. Por consiguiente, debe revisarlo de vez en cuando
          para asegurarse de estar al tanto de las disposiciones aplicables más
          actualizadas. Su uso continuado de nuestros Servicios después de que
          hayamos publicado cambios de esta Política de Privacidad indica que
          usted acepta los términos modificados de esta Política de Privacidad.
        </p>
      </div>

      <div id="13_sec">
        <h4>13. Cómo contactarnos</h4>
        <p>
          Para ponerse en contacto con nosotros en relación con esta Política de
          Privacidad por correo, por favor utilice una de las siguientes
          direcciones:
        </p>
        <ul>
          <li>
            Terra Adriatica Solutions d.o.o, Trg Nikole Šubića Zrinskog 14, 10
            000 Zagreb, Republika Hrvatska
          </li>
          <li>
            Terralogix Group Ltd, Citygate, St. James Boulevard, Newcastle Upon
            Tyne, NE1 4JE, United Kingdom.
          </li>
        </ul>
        <p>
          Para contactarnos con respecto a esta Política de Privacidad por
          correo electrónico, por favor, hágalo a la dirección
          privacy@terraadriatica.hr.
        </p>
        <p>
          Investigaremos e intentaremos resolver cualquier queja o disputa que
          recibamos con respecto al procesamiento de su Información Personal. En
          la medida en que su Información Personal sea Información Personal de
          la UE y usted no esté satisfecho con la resolución de cualquier queja,
          puede ponerse en contacto
          <a href="https://ico.org.uk/global/contact-us/" target="_blank"
            >con la Oficina del Comisionado de Información del Reino Unido</a
          >
          para obtener más información y asistencia.
        </p>
      </div>
    </div>

    <div v-else class="container pt-5 pb-5">
      <div>
        <h3>Our Commitment to Privacy</h3>
        <br />
        <p>
          At Terralogix, we are committed not only to your data privacy, rights
          and security but also to company transparency. As guardians of some of
          your personal information, we take this very seriously. With this in
          mind, we want you to know how we collect and use your personal data.
        </p>

        <p class="fw-600">
          Transparency. We work hard to be transparent about what Personal
          Information we collect and process.
        </p>

        <p class="fw-600">
          Simplicity. We try to be as clear and concise as possible without
          technical and legal jargon to describe our privacy practices to help
          you make informed choices.
        </p>

        <p class="fw-600">
          Control. We give you control over the Personal Information you provide
          to us, and how it is used, shared, and retained.
        </p>
      </div>

      <div class="mt-4">
        <h3 class="fw-600">Our Privacy Policy</h3>
        <p>
          The Terralogix Group is made up of different legal entities and other
          affiliates, including:
        </p>
        <ul>
          <li>
            Terra Adriatica Solutions d.o.o. (trading as Terra Adriatica), a
            company registered in Croatia, OIB: 83760633400 with registered
            address: Trg Nikole Šubića Zrinskog 14, 10 000 Zagreb, Republic of
            Croatia.
          </li>
          <li>
            Terralogix Group Ltd – a company registered in England and Wales,
            Company # 11736079 with registered address: Citygate, St. James
            Boulevard, Newcastle Upon Tyne, NE1 4JE, United Kingdom.
          </li>
        </ul>
        <p>
          This Privacy Policy is issued on behalf of the Terralogix Group so
          when we mention “Terralogix,” "we," "us" or "our" in this privacy
          statement, we are referring to the relevant company in the Terralogix
          Group responsible for processing your Personal Data.
        </p>
        <p>
          Terralogix offers access to an array of products and services,
          including but not limited to, Terralogix’s various databases. The
          services, products, data and information provided by Terralogix on
          this Site and/or through other websites and channels it operates,
          together with any Applications and any other associated software
          applications and functionality are collectively referred to in this
          Privacy Policy as “the Services.” Use of the Services is governed by
          our Terms and Conditions of use and any other service agreement(s) (<b
            >“Customer Agreement”</b
          >) between Terralogix and its customers (<b>“Customers”</b>).
        </p>
        <p>
          This Privacy Policy describes how Terralogix treats information that
          you, other users, or our Customers may provide to us, or which we
          otherwise collect, which can directly or indirectly identify you (your
          <b>“Personal Information”</b>), how we may use your Personal
          Information, how we protect the security of your Personal Information
          and how you may change or request that we amend your Personal
          Information our Services.
        </p>
        <p>
          To the extent Personal Information is governed and protected by
          European Union (<b>“EU”</b>) and/or UK data protection laws (such
          Personal Information is hereinafter referred to as
          <b>“EU Personal Information”</b>) we will ensure that we comply with
          all relevant EU and/or UK data protection laws when processing EU
          Personal Information.
        </p>
        <p>
          The Personal Information that we intend to process includes your Name,
          Surname, Email address, Address (street, city, county), Phone, Gender,
          Nickname, Date of birth, OIB (unique identification number), Place of
          birth, Place of last residence, your IP address, your password and
          your username, and where appropriate, your activity and transactions
          via the Services.
        </p>
        <p>
          Terralogix respects the privacy of its Product users and Site visitors
          and strives to protect the security of the Personal Information it
          processes.
        </p>

        <div>
          <h5>Click the link below for Terralogix’s policy on each topic:</h5>
          <ol>
            <li>
              <a href="#1_sec"
                >How and When We Collect and Use Personal Information</a
              >
            </li>
            <li>
              <a href="#2_sec">Our Purposes for Processing</a>
            </li>
            <li>
              <a href="#3_sec">Our Use of Cookies</a>
            </li>
            <li>
              <a href="#4_sec"
                >Information You and Other Customers Provide to Us</a
              >
            </li>
            <li>
              <a href="#5_sec">How we Share your Personal Information</a>
            </li>
            <li>
              <a href="#6_sec"
                >How we Protect the Security of your Personal Information</a
              >
            </li>
            <li>
              <a href="#7_sec">Children’s Privacy</a>
            </li>
            <li>
              <a href="#8_sec"
                >Your Rights Regarding your Personal Information</a
              >
            </li>
            <li>
              <a href="#9_sec">How long we retain your Personal Information</a>
            </li>
            <li>
              <a href="#10_sec"
                >How we use your Personal Data to inform you and market to
                you</a
              >
            </li>
            <li>
              <a href="#11_sec">Other Websites</a>
            </li>
            <li>
              <a href="#12_sec">Changes to this Privacy Policy</a>
            </li>
            <li>
              <a href="#13_sec">How to Contact us</a>
            </li>
          </ol>
        </div>
      </div>

      <div id="1_sec">
        <h5 class="fw-600">
          1. How and When We Collect and Use Personal Information
        </h5>

        <p>
          We collect information from and about you when you register with us or
          use our Services, or from publicly available sources. Additionally, we
          may also collect the following Personal Information:
        </p>

        <p>
          (a) Customer submitted information: We will process your Personal
          Information when it is provided to us by another Customer in
          connection with the use of our Services. In some situations, we may
          also record your username and/or password which you provide to sign-in
          to the Services.
        </p>
        <p>
          We may use this Personal Information to respond to specific requests
          for information about the Services and other inquiries. We only use
          the Personal Information provided by you or our Customers when signing
          up to use a Service for purposes related to the provision of those
          Services to you and our Customers.
        </p>

        <p>
          (b) Information we get from your use of our Services: When you access
          our Services, our systems automatically record information such as
          your Internet Protocol (“IP”) address, mobile device identifier,
          browser type and characteristics, and whether and how frequently you
          use this our Services.
        </p>
        <p>
          We process this Personal Information to understand users’ preferences,
          to better serve users, and to improve our Services.
        </p>

        <p>
          (c) Information you or other Customers Upload to our Services: The
          Services may permit the uploading, posting, storage and viewing of
          Personal Information or other content submitted by you or another
          Customer of our Services under the Terms and Conditions of use (<b
            >“User-Submitted Content”</b
          >). User-Submitted Content is provided by the Customer and, except as
          otherwise provided in this paragraph and paragraph 1(d)
          <b>“Aggregated Data”</b> below, will be processed by Terralogix as a
          data processor in accordance with the Customer’s instructions. The
          Customer will be responsible for all User-Submitted Content, including
          complying with the applicable Terms of Use, Customer Agreement and all
          applicable privacy, data security, and other laws and regulations.
          Except as otherwise provided in this paragraph and paragraph 1(d)
          <b>“Aggregated Data”</b> below.
        </p>

        <p>
          We process User-Submitted Content primarily for the provision of the
          Services. We also use this Personal Information to address a system,
          security, or support issue, or a suspected violation of our Terms and
          Conditions of use or Customer Agreement, and to create aggregate
          information (see 1(d) <b>“Aggregate Data”</b>
          below)
        </p>

        <p>
          (d) Aggregated Data: We may collect information regarding use of our
          Services, or about a category of users of our Services, and combine
          that information with other information pertaining to use of our
          Services by you and other users. In addition, we may create
          “Aggregated Data” using the User-Submitted Content and may use such
          Aggregated Data in connection with our provision of the Services and
          for other Terralogix business purposes and to create new products and
          services. “Aggregated Data” means data resulting from the aggregation,
          calculation, analysis or compilation of User-Submitted Content and/or
          data from other sources, including other customers of Terralogix;
          provided that (i) such resulting data does not identify you or any
          other individual or Customer as the source of any such data, and (ii)
          the identities of Customer or individual is not disclosed by or
          discernible from the resulting data without an extraordinary amount of
          time or effort. No such Aggregated Data will include your Personal
          Information.
        </p>
      </div>

      <div id="2_sec">
        <h4>2. Grounds for Processing Personal Information</h4>

        <p>
          The Personal Information that we may need to process includes your
          Name, Surname, Email address, Address (street, city, county), Phone,
          Gender, Nickname, Date of birth, OIB (unique identification number),
          Place of birth, Place of last residence, your IP address, your
          password and your username, and where appropriate, your activity and
          transactions on our Services. We need such Personal Information
          primarily to allow us to perform the contracts with our Customers and
          enable you to use the Services, to enable us to comply with legal
          obligations, for the purpose of addressing a system, security or
          support issue, or a suspected violation of the Terms and Conditions of
          use, the Customer Agreement or the law and, in many cases we may use
          your Personal Information to pursue legitimate interests of our own
          and those of third parties, provided your interests and fundamental
          rights do not override those interests. The situations in which we
          will process your Personal Information for our legitimate interests
          include some or all of the following:
        </p>

        <div>
          <div class="p-cont d-flex">
            <div class="side-num">a.</div>
            <div class="side-text">
              <span>
                To provide the Services, to enhance, modify, personalize or
                otherwise improve and support the Services and to conduct
                communications for the benefit of our Customers and users of the
                Services.
              </span>
            </div>
          </div>

          <div class="p-cont d-flex">
            <div class="side-num">b.</div>
            <div class="side-text">
              <span>
                To update and maintain the accuracy of Personal Information.
              </span>
            </div>
          </div>

          <div class="p-cont d-flex">
            <div class="side-num">c.</div>
            <div class="side-text">
              <span>
                To enhance network and information security, including
                preventing unauthorised access to our computer and electronic
                communications systems and preventing malicious software
                distribution.
              </span>
            </div>
          </div>

          <div class="p-cont d-flex">
            <div class="side-num">d.</div>
            <div class="side-text">
              <span>
                To conduct data analytics studies to review and better
                understand how users interact with the Services.
              </span>
            </div>
          </div>
        </div>

        <p>
          Whenever we process Personal Information for these purposes, we ensure
          that we keep your Personal Information rights in high regard and to
          take account of these rights. You have the right to object to this
          processing if you wish, and if you do wish to do so please see
          <a href="#8_sec"
            >8. "Your Rights Regarding your Personal Information"</a
          >
          below. Please bear in mind that if you do object and we cannot
          demonstrate compelling legitimate grounds to continue processing your
          Personal Information, we will stop processing your Personal
          Information which may affect our ability to provide you with certain
          services and carry out tasks to your benefit. It may also affect your
          ability to use the Products. Please also see your rights regarding
          your Personal Information in
          <a href="#8_sec"
            >8. "Your Rights Regarding your Personal Information"</a
          >.
        </p>
      </div>

      <div id="3_sec">
        <h4>3. Our Use of Cookies</h4>
        <p>
          We use cookies, which are small data files that this Site may transfer
          to your browser. You can find more information about cookies at
          websites such as www.allaboutcookies.org and www.youronlinechoices.eu.
          For more information on what cookies Terralogix use and why, please
          see our <a href="/en/cookie-policy">Cookie Policy</a>.
        </p>
      </div>

      <div id="4_sec">
        <h4>4. Information You and Other Customers Provide to Us</h4>
        <p>
          We process information you provide about yourself or other living
          individuals when you voluntarily contribute to the Services or
          participate in community discussions. We may also obtain your Personal
          Information from a Customer who adds you as part of their family tree
          in connection with our Services. Terralogix does not own or control
          such Personal Information, except as provided in paragraphs 1(c) and
          1(d) above, and we handle such Personal Information only in the
          capacity of providing our Services with our Customers consent. Our
          Customers are responsible for making sure that privacy rights are
          respected, including ensuring appropriate disclosures about third
          party data collection and use. To the extent that we are acting in the
          capacity of providing services, we will process Personal Information
          in accordance with the Terms and Conditions of use of our Services,
          the terms of our Customer Agreement with the Customer and the
          Customer’s lawful instruction. If you have any questions or concerns
          about the processing of any of such Personal Information, please
          contact us by email to privacy@terraadriatica.hr.
        </p>
      </div>

      <div id="5_sec">
        <h4>5. How we Share your Personal Information</h4>
        <p>
          (a)   Among Terralogix Group Companies: We may share Personal
          Information among the Terralogix companies including those described
          above for purposes of providing the Products and in support of this
          Site.
        </p>
        <p>
          (b)   With Third Party Service Providers: We may provide your Personal
          Information to our third-party service providers so that they may
          assist us in connection with operating our Services; for example, our
          data centers, support services providers, developers and other similar
          or related services. We typically enter into written contracts with
          our third-party service providers, requiring them to use Personal
          Information only for that purpose and require assurances that they
          will appropriately protect Personal Information entrusted to them.
        </p>
        <p>
          (c)   For Law Enforcement and Legal Purposes: We may disclose your
          Personal Information as we deem necessary or appropriate: (i) under
          applicable law, (ii) to comply with legal process; (iii) to respond to
          requests from public and government authorities including public and
          government authorities outside your country of residence; (iv) to
          enforce our Terms and Conditions of use and Customer Agreements; (v)
          to protect our operations or those of any of our affiliates; (vi) to
          protect our rights, privacy, safety or property, and/or those of our
          affiliates, you or others; and (vii) to allow us to pursue available
          remedies or limit the damages that we may sustain.
        </p>
        <p>
          (d)   Transfers outside the European Economic Area (“EEA”): The
          sharing of your EU Personal Information as described above may involve
          the transfer of your EU Personal Information outside the EEA,
          including to and in the United States. Whenever we transfer any EU
          Personal Information outside the EEA we will have or put in place an
          adequate level of protection to ensure that any such transfers comply
          with applicable EU and/or UK data protection laws, including, with
          respect to transfers among Terralogix affiliates, the standard data
          protection clauses adopted by the EU Commission. If you require
          further information about this protective measure, you can request it
          from the Data Protection Team by contacting us by using our website
          contact form, by email to privacy@terraadriatica.hr.
        </p>
      </div>

      <div id="6_sec">
        <h4>6. How we Protect the Security of your Personal Information</h4>

        <p>
          We are committed to maintaining the security of Personal Information.
          We employ reasonable organizational, technical and
          administrative measures to secure and protect Personal Information
          collected as described in this Privacy Policy from loss, theft, misuse
          and unauthorized access, disclosure, alteration and destruction. We
          permit persons working for us to access and use the Personal
          Information you provide only to serve legitimate business and service
          needs consistent with this Privacy Policy. We also require our
          third-party service providers to implement appropriate security
          measures.
        </p>

        <p>
          Please remember, though, that no method of electronic transmission or
          storage is 100% secure. If you have reason to believe that your
          interaction with us is no longer secure, please immediately notify us
          of the problem by contacting us (see<a href="#11_sec"
            >11. “How to Contact Us”</a
          >
          below). We retain Personal Information for as long as we reasonably
          require it for legal, business or contractual purposes. In determining
          data retention periods, we take into consideration local laws,
          contractual obligations, technology constraints and the expectations
          and requirements of our Customers. When we no longer need Personal
          Information, we securely delete or destroy it.
        </p>
      </div>

      <div id="7_sec">
        <h4>7. Children's Privacy</h4>

        <p>
          We are concerned to protect the privacy of children aged 16 or under.
          We do not knowingly solicit or collect information from individuals
          under 16. If we become aware that a child under the age of 16 has
          provided us with Personal Data, we will close the account and restrict
          their information. If you believe that we might have collected
          Personal Data from a child under 16, please contact us by using our
          website contact form by email to privacy@terraadriatica.hr.
        </p>
      </div>

      <div id="8_sec">
        <h4>8. Your rights Regarding your Personal Information</h4>

        <p>
          You may have the right to access the Information that we process about
          you and to request that we correct, amend, or delete it if it is
          inaccurate or processed in breach of applicable laws, if such right is
          provided by law in the applicable jurisdiction. These rights may not
          apply in some cases and, if you make a request, we shall inform you if
          that is the case. If you would like to request access to, or a
          correction or amendment to, your EU Personal Information or request
          deletion of your EU Personal Information, please contact us by email
          at privacy@terraadriatica.hr.
        </p>
      </div>

      <div id="9_sec">
        <h4>9. How long we retain your Personal Information</h4>

        <p>
          Terralogix maintains reasonable procedures to help ensure that your
          Personal Data is reliable for its intended use, accurate, complete,
          and current.
        </p>
        <ul>
          <li>
            We will only retain your Personal Data for as long as necessary to
            fulfil the purposes we collected it for, including for the purposes
            of satisfying any legal, accounting, or reporting requirements, as
            well as other factors required by General Data Protection Regulation
            or other laws to which we are subject.
          </li>
          <li>
            When it is no longer necessary to retain your Personal Data, we will
            securely delete it, subject to applicable law and regulations.
          </li>
          <li>
            When it is no longer necessary to retain your Personal Data, we will
            securely delete it, subject to applicable law and regulations.
          </li>
          <li>
            In some circumstances, we may anonymize your Personal Data (so that
            it can no longer be associated with you) for research or statistical
            purposes in which case we may use this information indefinitely
            without further notice to you.
          </li>
          <li>
            If you request that we stop sending you marketing materials, we will
            continue to keep a record of your contact details and appropriate
            information to enable us to comply with your request not to be
            contacted by us.
          </li>
        </ul>
      </div>

      <div id="10_sec">
        <h4>
          10. How we use your Personal Data to inform you and market to you
        </h4>
        <p>
          In compliance with applicable laws, we may send you personalized
          marketing information, including by email, and such information may
          include product and service updates, industry news, our events,
          activities and offers, information about our business and personnel
          and tips. We may combine your Personal Data such as age, transaction
          history, account usage to improve the value and specificity of these
          communications.
        </p>
        <p>
          You can ask us to stop sending you marketing messages or information
          at any time by following the “unsubscribe” link on any marketing
          message sent to you or by updating your contact preferences directly
          on the Terralogix Platform. Please note, it may take up to [10] days
          for this to take effect. Where you opt out of receiving these
          marketing messages or publications, this will not apply to Personal
          Data collected by or provided to us in connection with a specific
          purpose, request, order, event or activity or any dealings with you,
          and you may still receive emails from us relating to the operation of
          the Terralogix service, like transaction confirmations
        </p>
      </div>

      <div id="11_sec">
        <h4>11. Other Websites</h4>
        <p>
          This Privacy Policy applies to Personal Information collected in
          connection with your use of our Services. Information submitted on a
          third-party website linked to our Services is subject to the terms of
          the Privacy Policy applicable to such third-party website, and not the
          terms of this Privacy Policy. Terralogix is not associated with these
          third-party websites and does not review their privacy policies. We
          cannot guarantee the privacy of any information that may be obtained
          by third parties at these other websites. We strongly encourage you to
          review the privacy policies of any website you visit before supplying
          personal information through that website.
        </p>
      </div>

      <div id="12_sec">
        <h4>12. Changes to the Privacy Policy</h4>
        <p>
          We may revise this Privacy Policy from time to time in order to
          address new or revised website functionality, new or revised aspects
          of the Products, Internet technology, or applicable law. The handling
          of Personal Information is governed by the version of this Privacy
          Policy in effect when such information is received. Therefore, you
          should review it from time to time to be sure that you are aware of
          the most up-to-date applicable provisions. Your continued use of our
          Services after we have posted changes to this Privacy Policy indicates
          your agreement to the amended terms of this Privacy Policy.
        </p>
      </div>

      <div id="13_sec">
        <h4>13. How to Contact Us</h4>
        <p>
          To contact us regarding this Privacy Policy by post, please use one of
          the following addresses:
        </p>
        <ul>
          <li>
            Terra Adriatica Solutions d.o.o., Trg Nikole Šubića Zrinskog 14, 10
            000 Zagreb, Republika Hrvatska
          </li>
          <li>
            Terralogix Group Ltd, Citygate, St. James Boulevard, Newcastle Upon
            Tyne, NE1 4JE, United Kingdom.
          </li>
        </ul>
        <p>
          To contact us regarding this Privacy Policy by email please do so via
          privacy@terraadriatica.hr
        </p>
        <p>
          We will investigate and attempt to resolve any complaints or disputes
          we receive regarding the processing of your Personal Information. To
          the extent your Personal Information is EU Personal Information, and
          you are unsatisfied with the resolution of any complaint, you may
          contact the
          <a href="https://ico.org.uk/global/contact-us/" target="_blank"
            >UK Information Commissioner’s Office</a
          >
          for further information and assistance.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PrivacyPolicy"
};
</script>

<style scoped>
.p-cont {
  margin-bottom: 10px;
}

.side-num {
  width: 70px;
}

.side-text {
  width: calc(100% - 70px);
}
</style>
